$(() => {
  $('.nav-item-link, .footer-link').on('click', (e) => {
    const id = 1 + $(e.target).parent().index(this);
    // console.log('> e.target =', e.target);
    console.log('> id =', id);

    let top = 0;
    if (id === 1) top = $('#section-1').offset().top;
    if (id === 2) top = $('#section-2').offset().top;
    if (id === 3) top = $('#section-3').offset().top;
    if (id === 4) top = $('#section-4').offset().top;
    if (id === 5) top = $('#section-5').offset().top;
    if (id === 6) top = $('#section-6').offset().top;

    $('html, body').animate(
      {
        scrollTop: top,
      },
      1000
    );

    $('.navbar-collapse').collapse('hide');
  });
});

$(function () {
  $('[data-toggle="popover"]').popover();
});
